import notification from "antd/es/notification";
import { push } from "connected-react-router";
import uniq from "lodash-es/uniq";
import { defineMessages } from "react-intl";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import RecordSource from "@mapmycustomers/shared/enum/RecordSource";
import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import {
  Company,
  EntitiesSupportingRoutes,
  EntityType,
  EntityTypesSupportingRoutes,
  EntityTypeSupportingLeadGen,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import CompanyWithLeadId from "@mapmycustomers/shared/types/entity/CompanyWithLeadId";
import PersonWithLeadId from "@mapmycustomers/shared/types/entity/PersonWithLeadId";
import { RoutePayload } from "@mapmycustomers/shared/types/entity/Route";
import File from "@mapmycustomers/shared/types/File";
import { MapLeadsResponse } from "@mapmycustomers/shared/types/map";
import Organization from "@mapmycustomers/shared/types/Organization";
import PersistentLeadFinderSettings from "@mapmycustomers/shared/types/persistent/PersistentLeadFinderSettings";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import {
  convertCoordinatesToGeoPoint,
  convertLatLngToCoordinates,
  convertPlatformBoundsToLatLngBounds,
} from "@mapmycustomers/shared/util/geo/GeoService";

import { getUploadedFileListIds as getCompanyFileIds } from "@app/component/createEditEntity/CreateCompanyModal/store";
import { getUploadedFileListIds as getPersonFileIds } from "@app/component/createEditEntity/CreatePersonModal/store";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import AreaSearchMode from "@app/enum/AreaSearchMode";
import Path from "@app/enum/Path";
import MapMode from "@app/scene/map/enums/MapMode";
import { enterMode, exitMode, fetchPins, hideSidebar } from "@app/scene/map/store/actions";
import {
  getAddRecordsEntityType,
  getFilteredSelectedRecords,
  getLeadFinderConfiguration,
  getLeadFinderMode,
  getLeadFinderProcessing,
  getLeadFinderQuery,
  getLeadFinderRecords,
  getLeadFinderSearchDistance,
  getLeadFinderSettings,
  getMapViewport,
  isConfigurationVisible,
} from "@app/scene/map/store/selectors";
import {
  LEAD_FINDER_MAX_SEARCHES,
  LEAD_FINDER_QUERY_LIMIT_FIRST,
  LEAD_FINDER_QUERY_LIMIT_FOLLOWING,
} from "@app/scene/map/utils/consts";
import { convertPlaceToEntity } from "@app/scene/map/utils/convertPlaceToEntity";
import { RequestOptions } from "@app/store/api/BaseApiService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import {
  getOrganizationId,
  getPosition,
  isLeadFinderEnabled,
  isLeadFinderSaveEnabled,
} from "@app/store/iam";
import { updateMetadata } from "@app/store/iam/actions";
import MapViewportState from "@app/types/map/MapViewportState";
import { METERS_IN_MILE } from "@app/util/consts";
import { allSettled, SettleResult } from "@app/util/effects";
import getValidationErrors from "@app/util/errorHandling/getValidationErrors";
import {
  FIRST_WARNING_LIMIT,
  SECOND_WARNING_LIMIT,
  THIRD_WARNING_LIMIT,
} from "@app/util/leadFinder/showLeadFinderLimitAlert";
import { leadIdGetter } from "@app/util/map/idGetters";
import removeUndefinedFields from "@app/util/removeUndefinedFields";

import {
  addRecordsToRoute,
  clearLeadFinderLocation,
  clearLeadFinderRecords,
  enterLeadFinderMode,
  exitLeadFinderMode,
  filterRoutes,
  geocodeLocation,
  hideDuplicates,
  hideLeadFinder,
  hideLeads,
  initializeAddRecords,
  initializeLeadFinderMode,
  removeSearchHistoryItem,
  saveSearch,
  setAddRecordsVisibility,
  setCenterPoint,
  setConfigurationVisibility,
  setCustomSearchDistances,
  setDistanceSettings,
  setSearchDistance,
  setSearchHistory,
  showHiddenLeads,
  showLeadFinder,
  showSavedSearchesModal,
  startLeadFinderSearch,
  submitAddRecords,
  toggleLeadFinder,
  updateAddRecordsProgress,
} from "./actions";
import { LeadFinderLimit, LeadFinderQueryState } from "./LeadFinderState";

const messages = defineMessages({
  hideLeadDescription: {
    id: "map.tool.leadFinder.lead.hide.successs.description",
    defaultMessage: "You will no longer see this result unless hidden results are toggled on.",
    description: "Lead hidden success description",
  },
  hideLeadSuccessTitle: {
    id: "map.tool.leadFinder.lead.hide.successs.title",
    defaultMessage: "Result hidden successfully",
    description: "Lead hidden success title",
  },
  saved: {
    id: "map.tool.leadFinder.toolbar.search.saveNotification",
    defaultMessage: "Search saved",
    description: "Search saved - notification title",
  },
  savedDescription: {
    id: "map.tool.leadFinder.toolbar.search.saveNotificationDescription",
    defaultMessage: "You can access it in the future under “Quick Search” when using Lead Finder",
    description: "Search saved - notification description",
  },
  showLeadDescription: {
    id: "map.tool.leadFinder.lead.show.success.description",
    defaultMessage: "You will always see this result in Lead Finder.",
    description: "Lead shown success description",
  },
  showLeadSuccessTitle: {
    id: "map.tool.leadFinder.lead.show.success.title",
    defaultMessage: "Result shown successfully",
    description: "Lead shown success title",
  },
});

export function* onStartLeadFinderSearch({
  payload,
}: ReturnType<typeof startLeadFinderSearch.request>) {
  try {
    const intl = i18nService.getIntl();
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewport: MapViewportState = yield select(getMapViewport);
    const mode: AreaSearchMode = yield select(getLeadFinderMode);
    const query: LeadFinderQueryState = yield select(getLeadFinderQuery);
    if (!query.search) {
      return;
    }
    const currentLimit: LeadFinderLimit = yield callApi("fetchLeadFinderLimits", orgId);
    const { orgLimit, userLimit } = currentLimit;
    const isOrgLimitReached = orgLimit.percentageConsumed >= 100 && orgLimit.limit !== 0;
    const isUserLimitReached =
      userLimit.percentageConsumed >= 100 && userLimit.userLeadGenCapEnabled;

    const previousLimit = localSettings.getRecentLeadFinderLimit();
    if (previousLimit && payload.onLimitThresholdReached) {
      if (
        (orgLimit.percentageConsumed < previousLimit.orgLimit.percentageConsumed &&
          orgLimit.limit !== 0) ||
        (userLimit.percentageConsumed < previousLimit.userLimit.percentageConsumed &&
          userLimit.userLeadGenCapEnabled)
      ) {
        // limit was reset, we don't need to do anything here
      } else if (
        (orgLimit.limit !== 0 &&
          ((previousLimit.orgLimit.percentageConsumed < FIRST_WARNING_LIMIT &&
            orgLimit.percentageConsumed >= FIRST_WARNING_LIMIT) ||
            (previousLimit.orgLimit.percentageConsumed < SECOND_WARNING_LIMIT &&
              orgLimit.percentageConsumed >= SECOND_WARNING_LIMIT) ||
            (previousLimit.orgLimit.percentageConsumed < THIRD_WARNING_LIMIT &&
              orgLimit.percentageConsumed >= THIRD_WARNING_LIMIT))) ||
        (userLimit.userLeadGenCapEnabled &&
          ((previousLimit.userLimit.percentageConsumed < FIRST_WARNING_LIMIT &&
            userLimit.percentageConsumed >= FIRST_WARNING_LIMIT) ||
            (previousLimit.userLimit.percentageConsumed < SECOND_WARNING_LIMIT &&
              userLimit.percentageConsumed >= SECOND_WARNING_LIMIT) ||
            (previousLimit.userLimit.percentageConsumed < THIRD_WARNING_LIMIT &&
              userLimit.percentageConsumed >= THIRD_WARNING_LIMIT)))
      ) {
        yield call(payload.onLimitThresholdReached, currentLimit);
        yield put(startLeadFinderSearch.failure());
        return;
      }
    }

    localSettings.setRecentLeadFinderLimit(currentLimit);

    if (isOrgLimitReached || isUserLimitReached) {
      if (payload.onLimitThresholdReached) {
        yield call(payload.onLimitThresholdReached, currentLimit);
      }
      yield put(startLeadFinderSearch.failure());
      return;
    }

    yield put(hideSidebar());
    yield put(setConfigurationVisibility({ visible: false }));
    if (!payload.refresh) {
      yield put(clearLeadFinderRecords());
    }

    // Add submitted search query to recent queries history
    // If save query present in saved searches - do not include. Also do not allow duplicates in recent history
    const savedSearchesEnabled: boolean = yield select(isLeadFinderSaveEnabled);
    const configuration: PersistentLeadFinderSettings = yield select(getLeadFinderSettings);
    const savedSearches = savedSearchesEnabled ? configuration.savedSearches : [];
    const recentSearches = savedSearches.includes(query.search)
      ? configuration.recentSearches
      : uniq([query.search, ...configuration.recentSearches]);
    const modifiedConfiguration = {
      recentSearches,
      savedSearches,
    };
    yield put(setSearchHistory(modifiedConfiguration));

    let point: LongLat = query.point ?? [0, 0];

    if (payload.refresh) {
      // If we're refreshing - always fetch leads around viewport center, because it has no sense to
      // request leads search around original point - probably there are no more results would be returned
      if (viewport.bounds) {
        const center = convertPlatformBoundsToLatLngBounds(viewport.bounds).getCenter();
        const geoPoint = convertCoordinatesToGeoPoint(convertLatLngToCoordinates(center));
        point = geoPoint.coordinates;
      }
    } else {
      switch (mode) {
        case AreaSearchMode.ADDRESS:
          if (!query.point) {
            const addressToCheck = {
              address: query.address?.address ?? "",
              city: query.address?.city ?? "",
              country: query.address?.country ?? "",
              postalCode: query.address?.postalCode ?? "",
              region: query.address?.region ?? "",
              regionCode: query.address?.regionCode ?? "",
            };
            const response: GeocodeResult = yield callApi("geocodeAddress", orgId, addressToCheck);
            if (response.geoPoint) {
              point = response.geoPoint.coordinates;
            } else {
              notification.error({
                message: intl?.formatMessage({
                  id: "map.tool.leadFinder.error.geocodingIssue",
                  defaultMessage: "Failed to find location",
                  description: "Failed to find location in Lead Finder",
                }),
              });
              yield put(startLeadFinderSearch.failure());
              return;
            }
          }
          break;

        case AreaSearchMode.CURRENT_USER_LOCATION: {
          const position: GeolocationPosition | undefined = yield select(getPosition);
          if (
            position?.coords?.longitude !== undefined &&
            position?.coords?.latitude !== undefined
          ) {
            point = [position.coords.longitude, position.coords.latitude];
          }
          break;
        }

        case AreaSearchMode.ENTITY:
          break;

        default:
          if (viewport.bounds) {
            const center = convertPlatformBoundsToLatLngBounds(viewport.bounds).getCenter();
            const geoPoint = convertCoordinatesToGeoPoint(convertLatLngToCoordinates(center));
            point = geoPoint.coordinates;
          }
          break;
      }
    }

    const searchDistance: number = yield select(getLeadFinderSearchDistance);
    const radius = searchDistance * (localSettings.doesUseSiUnits() ? 1000 : METERS_IN_MILE);

    const requestPayload = {
      $filters: {
        point,
        query: query.search,
        radius,
      },
      // For refresh load up to 20 new leads
      $limit: payload.refresh ? LEAD_FINDER_QUERY_LIMIT_FOLLOWING : LEAD_FINDER_QUERY_LIMIT_FIRST,
    };

    const response: MapLeadsResponse = yield callApi("fetchLeads", orgId, requestPayload);
    yield put(setCenterPoint(point));

    // Check if still processing request (e.g. not cancelled)
    const isProcessing: boolean = yield select(getLeadFinderProcessing);
    if (isProcessing) {
      if (payload.refresh) {
        const records: MapLeadsResponse["data"] = yield select(getLeadFinderRecords);

        const newRecords = response.data ?? [];
        const newRecordsMap = new Set(newRecords.map((lead) => leadIdGetter(lead)));
        const combinedRecords = [
          ...newRecords,
          ...records.filter((lead) => !newRecordsMap.has(leadIdGetter(lead))),
        ];

        const result = {
          records: combinedRecords,
          totalFilteredRecords: newRecords.length,
          totalRecords: newRecords.length,
        };

        yield put(startLeadFinderSearch.success(result));

        if (payload.callback) {
          // Calculate bounding area of all records in the list and send it to callback
          const bounds = new google.maps.LatLngBounds();
          combinedRecords.forEach((lead) => {
            if (lead.geometry?.location) {
              bounds.extend(lead.geometry.location);
            }
          });

          payload.callback(bounds.isEmpty() ? undefined : bounds);
        }
      } else {
        const records = response.data ?? [];
        const result = {
          records,
          totalFilteredRecords: response.count,
          totalRecords: response.total,
        };

        yield put(startLeadFinderSearch.success(result));

        if (payload.callback) {
          // Calculate bounding area of all records in the list and send it to callback
          const bounds = new google.maps.LatLngBounds();
          records.forEach((lead) => {
            if (lead.geometry?.location) {
              bounds.extend(lead.geometry.location);
            }
          });

          payload.callback(bounds.isEmpty() ? undefined : bounds);
        }
      }

      if (!payload.refresh) {
        yield put(enterLeadFinderMode());
      }
    }
  } catch (error) {
    yield put(startLeadFinderSearch.failure());
    yield put(handleError({ error }));
  }
}

export function* onGeocodeLocation({ payload }: ReturnType<typeof geocodeLocation.request>) {
  if (!payload.point) {
    return;
  }

  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const result: GeocodeResult = yield callApi("reverseGeocodeAddress", orgId, {
      coordinates: payload.point,
      type: "Point",
    });

    const address = result.address as Address;
    const location = address.formattedAddress ?? "";

    yield put(geocodeLocation.success({ address, location }));
  } catch (error) {
    yield put(geocodeLocation.failure());
    yield put(handleError({ error }));
  }
}

function* onInitializeLeadFinderMode({
  payload,
}: ReturnType<typeof initializeLeadFinderMode.request>) {
  try {
    yield put(enterMode(MapMode.LEAD_FINDER));

    const records: MapLeadsResponse["data"] = yield select(getLeadFinderRecords);

    if (payload.callback) {
      const bounds = new google.maps.LatLngBounds();
      records.forEach((lead) => {
        if (lead.geometry?.location) {
          bounds.extend(lead.geometry.location);
        }
      });

      payload.callback(bounds.isEmpty() ? undefined : bounds);
    }

    yield put(initializeLeadFinderMode.success());
  } catch (error) {
    yield put(initializeLeadFinderMode.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveSearchHistoryItem({ payload }: ReturnType<typeof removeSearchHistoryItem>) {
  try {
    const configuration: PersistentLeadFinderSettings = yield select(getLeadFinderConfiguration);

    const savedSearchesEnabled: boolean = yield select(isLeadFinderSaveEnabled);
    if (!savedSearchesEnabled) {
      const history: PersistentLeadFinderSettings = {
        ...configuration,
        savedSearches: [],
      };
      yield put(setSearchHistory(history));
      return;
    }

    const history: PersistentLeadFinderSettings = {
      ...configuration,
      recentSearches: (configuration.recentSearches ?? [])
        .filter((search) => search !== payload.search)
        .slice(0, LEAD_FINDER_MAX_SEARCHES - 1),
      savedSearches: (configuration.savedSearches ?? [])
        .filter((search) => search !== payload.search)
        .slice(0, LEAD_FINDER_MAX_SEARCHES - 1),
    };
    yield put(setSearchHistory(history));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onSaveSearch({ payload }: ReturnType<typeof saveSearch>) {
  try {
    const configuration: PersistentLeadFinderSettings = yield select(getLeadFinderConfiguration);

    const savedSearchesEnabled: boolean = yield select(isLeadFinderSaveEnabled);
    if (!savedSearchesEnabled) {
      const history: PersistentLeadFinderSettings = {
        ...configuration,
        savedSearches: [],
      };
      yield put(setSearchHistory(history));
      return;
    }

    if (
      Array.isArray(configuration.savedSearches) &&
      configuration.savedSearches.length >= LEAD_FINDER_MAX_SEARCHES
    ) {
      yield put(showSavedSearchesModal());
      return;
    }

    const history: PersistentLeadFinderSettings = {
      ...configuration,
      recentSearches: (configuration.recentSearches ?? [])
        .filter((search) => search !== payload.search)
        .slice(0, LEAD_FINDER_MAX_SEARCHES - 1),
      savedSearches: [
        payload.search,
        ...(configuration.savedSearches ?? [])
          .filter((search) => search !== payload.search)
          .slice(0, LEAD_FINDER_MAX_SEARCHES - 1),
      ],
    };
    yield put(setSearchHistory(history));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        description: intl.formatMessage(messages.savedDescription),
        message: intl.formatMessage(messages.saved),
      });
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onSaveSearchHistory({ payload }: ReturnType<typeof setSearchHistory>) {
  try {
    const history: PersistentLeadFinderSettings = yield select(getLeadFinderConfiguration);
    yield put(
      updateMetadata.request({
        leadFinderSettings: {
          ...history,
          ...payload,
        },
      })
    );
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onSetDistanceSettings({
  payload: { customDistances, searchDistance },
}: ReturnType<typeof setDistanceSettings>) {
  try {
    if (Array.isArray(customDistances)) {
      yield put(setCustomSearchDistances({ customDistances }));
    }

    if (searchDistance !== undefined) {
      yield put(setSearchDistance({ distance: searchDistance }));
    }

    const leadFinderSettings: PersistentLeadFinderSettings = yield select(
      getLeadFinderConfiguration
    );

    yield put(
      updateMetadata.request({
        leadFinderSettings,
      })
    );
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onInitializeAddRecords({ payload }: ReturnType<typeof initializeAddRecords.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const routes: ListResponse<Route> =
      payload.entityType === EntityType.COMPANY
        ? yield callApi("fetchCompanyRoutes", orgId, {
            $filters: { includeAccessStatus: true },
            $limit: 1000,
            $order: ["name"],
          })
        : yield callApi("fetchPeopleRoutes", orgId, {
            $filters: { includeAccessStatus: true },
            $limit: 1000,
            $order: ["name"],
          });

    yield put(
      initializeAddRecords.success({
        routes: routes.data,
      })
    );
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onEnterLeadFinderMode() {
  try {
    yield put(hideSidebar());
    yield put(enterMode(MapMode.LEAD_FINDER));

    yield put(setConfigurationVisibility({ visible: false }));

    yield put(push(`${Path.MAP}/leads`));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onExitLeadFinderMode() {
  try {
    yield put(hideSidebar());
    yield put(exitMode());

    yield put(clearLeadFinderLocation());
    yield put(clearLeadFinderRecords());

    yield put(push(Path.MAP));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onShowLeadFinder() {
  try {
    const isEnabled: boolean = yield select(isLeadFinderEnabled);
    yield put(setConfigurationVisibility({ visible: isEnabled }));

    if (isEnabled) {
      const savedSearchesEnabled: boolean = yield select(isLeadFinderSaveEnabled);
      const configuration: PersistentLeadFinderSettings = yield select(getLeadFinderSettings);
      const modifiedConfiguration = {
        ...configuration,
        savedSearches: savedSearchesEnabled ? configuration.savedSearches : [],
      };
      yield put(setSearchHistory(modifiedConfiguration));

      if (configuration.searchDistance && !isNaN(configuration.searchDistance)) {
        yield put(setSearchDistance({ distance: configuration.searchDistance }));
      }
      if (Array.isArray(configuration.customDistances)) {
        yield put(setCustomSearchDistances({ customDistances: configuration.customDistances }));
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onHideLeadFinder() {
  try {
    yield put(clearLeadFinderLocation());
    yield put(setConfigurationVisibility({ visible: false }));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onToggleLeadFinder() {
  try {
    const visible: boolean = yield select(isConfigurationVisible);
    yield put(visible ? hideLeadFinder() : showLeadFinder());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onSubmitAddRecords({ payload }: ReturnType<typeof submitAddRecords.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingLeadGen = yield select(getAddRecordsEntityType);
    const filteredSelectedRecords: MapLeadsResponse["data"] = yield select(
      getFilteredSelectedRecords
    );

    const fileIds: File["id"][] = yield select(
      entityType === EntityType.COMPANY ? getCompanyFileIds : getPersonFileIds
    );

    const selectedRecords: Partial<CompanyWithLeadId | PersonWithLeadId>[] =
      filteredSelectedRecords.map((lead) => ({
        ...removeUndefinedFields(convertPlaceToEntity(entityType, lead)),
        ...removeUndefinedFields(payload.entity),
        files: fileIds,
        leadGenId: lead.place_id,
      }));

    yield put(setAddRecordsVisibility({ visible: false }));
    yield put(exitLeadFinderMode());

    const options: RequestOptions = {
      headers: {
        "x-mmc-source": RecordSource.LEAD_GEN,
      },
      params: {
        layoutId: payload.layoutId,
      },
    };

    const response: Array<EntitiesSupportingRoutes[]> = yield entityType === EntityType.COMPANY
      ? callApi("createCompaniesInBulk", orgId, selectedRecords as Partial<Company>[], options)
      : callApi("createPeopleInBulk", orgId, selectedRecords as Partial<Person>[], options);

    const entities = response.flat();
    const entityIds = entities.map((entity: Company | Person) => entity.id);

    if (payload.customFields?.length) {
      yield callApi(
        "bulkUpsertCustomFieldsValues",
        false,
        orgId,
        entityType,
        entityIds.map((id) => ({ id, layoutId: payload.layoutId })),
        payload.customFields
      );
    }

    yield put(
      updateAddRecordsProgress({
        color: payload.entity.color || undefined,
        entityIds,
      })
    );

    if (entityType === EntityType.COMPANY && payload.personId) {
      const person: Person | undefined = yield callApi("fetchPerson", orgId, payload.personId);
      if (person) {
        yield callApi("updatePerson", orgId, undefined, {
          id: person.id,
          accounts: [...person.accounts, ...entityIds.map((id) => ({ id }))],
        });
      }
    }

    if (payload.groupIds?.length) {
      yield allSettled(
        payload.groupIds.map((groupId) =>
          callApi("addToGroup", orgId, groupId, entityType, entityIds)
        )
      );

      yield put(
        updateAddRecordsProgress({
          groupIds: payload.groupIds,
        })
      );
    }

    if (payload.routeIdsOrRoutePayloads?.length) {
      const newRoutePayloads = payload.routeIdsOrRoutePayloads.filter(
        (value) => typeof value !== "number"
      ) as RoutePayload[];
      const newRoutesResult: SettleResult<Route>[] = yield allSettled(
        newRoutePayloads.map(createRoute)
      );
      const newRouteIds = newRoutesResult
        .filter(({ error }) => !error)
        .map(({ result }) => (result as Route).id);

      yield all(
        [
          ...newRouteIds,
          ...(payload.routeIdsOrRoutePayloads.filter(
            (value) => typeof value === "number"
          ) as Route["id"][]),
        ].map((routeId) =>
          call(
            submitAddRecordsToRoute,
            routeId,
            entityIds,
            entityType,
            newRouteIds.includes(routeId)
          )
        )
      );
      if (newRouteIds.length) {
        yield put(initializeAddRecords.request({ entityType }));
      }
    }

    yield put(submitAddRecords.success());

    yield put(fetchPins.request({ request: {} }));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* createRoute(route: RoutePayload) {
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const startCoordsResult: GeocodeResult = yield callApi("geocodeAddress", orgId, {
    address: route.routeDetail.startAddress,
    city: null,
    country: null,
    postalCode: null,
    region: null,
  });

  let endCoordsResult: GeocodeResult | undefined = undefined;
  if (route.routeDetail.endAddress) {
    endCoordsResult = yield callApi("geocodeAddress", orgId, {
      address: route.routeDetail.endAddress,
      city: null,
      country: null,
      postalCode: null,
      region: null,
    });
  }

  const createdRoute: Route = yield callApi("createRoute", orgId, route.entityType, {
    name: route.name,
    routeDetail: {
      ...route.routeDetail,
      endGeoPoint: endCoordsResult ? endCoordsResult.geoPoint : undefined,
      startGeoPoint: startCoordsResult.geoPoint,
    },
  } as Partial<Route>);

  return createdRoute;
}

function* submitAddRecordsToRoute(
  routeId: Route["id"],
  entityIds: EntitiesSupportingRoutes["id"][],
  entityType: EntityTypesSupportingRoutes,
  isNewRoute?: boolean
) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const route: Route =
      entityType === EntityType.COMPANY
        ? yield callApi("fetchCompanyRoute", orgId, routeId, true, { includeAccessStatus: true })
        : yield callApi("fetchPeopleRoute", orgId, routeId, true, { includeAccessStatus: true });

    const previewPayload = {
      endGeoPoint: route.routeDetail.endGeoPoint,
      routeAccounts:
        entityType === EntityType.COMPANY
          ? // using uniq to preserve stops order
            uniq([...(route.routeAccounts ?? []).map(({ id }) => id), ...entityIds])
          : undefined,
      routeContacts:
        entityType === EntityType.PERSON
          ? // using uniq to preserve stops order
            uniq([...(route.routeContacts ?? []).map(({ id }) => id), ...entityIds])
          : undefined,
      routeType: route.routeDetail.type,
      startGeoPoint: route.routeDetail.startGeoPoint,
    };

    yield callApi("buildRoute", orgId, entityType, previewPayload);

    yield callApi(
      "updateRouteStops",
      orgId,
      entityType,
      routeId,
      entityIds,
      route.routeDetail.allottedTime
    );

    yield put(
      addRecordsToRoute.success({
        isNewRoute,
        routeId: routeId,
      })
    );
  } catch (error) {
    const errors = (getValidationErrors(error) ?? []).map((error) => error.message);
    if (errors.length) {
      // Do not display API error notification for validation failures, because they are actually displayed in modal
      yield put(
        addRecordsToRoute.failure({
          errors,
          routeId: routeId,
        })
      );
    } else {
      yield put(handleError({ error }));
    }
  }
}

function* onHideLeads({ payload }: ReturnType<typeof hideLeads.request>) {
  const orgId: Organization["id"] = yield select(getOrganizationId);
  try {
    yield callApi("updateHiddenLeads", orgId, payload.placeIds);
    yield put(hideDuplicates());
    yield put(hideLeads.success({ placeIds: payload.placeIds }));
    notification.success({
      description: i18nService.formatMessage(messages.hideLeadDescription),
      message: i18nService.formatMessage(messages.hideLeadSuccessTitle),
    });
  } catch {
    yield put(hideLeads.failure());
  }
}

function* onShowHiddenLeads({ payload }: ReturnType<typeof showHiddenLeads.request>) {
  const orgId: Organization["id"] = yield select(getOrganizationId);
  try {
    yield callApi("deleteHiddenLeads", orgId, payload.placeIds);
    yield put(hideDuplicates());
    yield put(showHiddenLeads.success({ placeIds: payload.placeIds }));
    notification.success({
      description: i18nService.formatMessage(messages.showLeadDescription),
      message: i18nService.formatMessage(messages.showLeadSuccessTitle),
    });
  } catch {
    yield put(showHiddenLeads.failure());
  }
}

export function* onFilterRoutes({ payload }: ReturnType<typeof filterRoutes.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const routes: ListResponse<Route> = yield callApi(
      payload.entityType === EntityType.COMPANY ? "fetchCompanyRoutes" : "fetchPeopleRoutes",
      orgId,
      {
        $filters: {
          includeAccessStatus: true,
          name: payload.query ? { $in: payload.query } : undefined,
        },
        $limit: 100,
        $order: ["name"],
      }
    );
    yield put(filterRoutes.success(routes.data));
  } catch (error) {
    yield put(filterRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* leadFinderSagas() {
  yield takeLatest(startLeadFinderSearch.request, onStartLeadFinderSearch);
  yield takeLatest(geocodeLocation.request, onGeocodeLocation);
  yield takeLatest(initializeLeadFinderMode.request, onInitializeLeadFinderMode);
  yield takeLatest(initializeAddRecords.request, onInitializeAddRecords);
  yield takeLatest(submitAddRecords.request, onSubmitAddRecords);
  yield takeLatest(showLeadFinder, onShowLeadFinder);
  yield takeLatest(hideLeadFinder, onHideLeadFinder);
  yield takeLatest(toggleLeadFinder, onToggleLeadFinder);
  yield takeLatest(saveSearch, onSaveSearch);
  yield takeEvery(removeSearchHistoryItem, onRemoveSearchHistoryItem);
  yield takeLatest(setSearchHistory, onSaveSearchHistory);
  yield takeLatest(setDistanceSettings, onSetDistanceSettings);
  yield takeLatest(enterLeadFinderMode, onEnterLeadFinderMode);
  yield takeLatest(exitLeadFinderMode, onExitLeadFinderMode);
  yield takeLatest(hideLeads.request, onHideLeads);
  yield takeLatest(showHiddenLeads.request, onShowHiddenLeads);
  yield takeLatest(filterRoutes.request, onFilterRoutes);
}
